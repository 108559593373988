<template>
  <v-container>
    <BaseTitlePage />

    <BaseSearchPage title="Cadastrar novo chamado">
      <template v-slot:form>
        <v-form
          @submit.prevent="save"
          ref="form"
          lazy-validation
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3">
              <BaseSupportCategories
                id="categoryId"
                name="categoryId"
                label="Categoria"
                required
                :rules="[required]"
                v-model="model.categoryId"
              />
            </v-col>
            <v-col cols="12" xl="9" lg="9" md="9" sm="9">
              <v-text-field
                id="subject"
                name="subject"
                label="Assunto"
                dense
                outlined
                required
                :rules="[required]"
                v-model="model.subject"
              />
            </v-col>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <v-textarea
                id="message"
                name="message"
                label="Digite sua mensagem"
                dense
                outlined
                required
                :rules="[required]"
                v-model="model.message"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <v-checkbox
                id="isFiles"
                name="isFiles"
                label="Deseja incluir algum anexo?"
                dense
                outlined
                v-model="isFiles"
                @change="changeIsFiles"
              />
            </v-col>

            <template v-if="isFiles">
              <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                <v-file-input
                  id="files"
                  name="files"
                  label="Adicionar arquivos"
                  dense
                  outlined
                  multiple
                  v-model="files"
                  @change="addFile"
                />
              </v-col>

              <v-col cols="12">
                <v-data-table
                  dense
                  disable-sort
                  hide-default-footer
                  item-key="id"
                  :headers="headers"
                  :items="items"
                >
                  <template v-slot:[`item.size`]="{ item }">
                    <span>{{ formatBytes(item.size) }}</span>
                  </template>

                  <template v-slot:[`item.action`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          color="error"
                          small
                          @click="remove(item)"
                        >
                          fa-regular fa-trash-can
                        </v-icon>
                      </template>
                      <span>Excluir</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <BaseButton
                id="btn-back"
                name="btn-back"
                title="Voltar"
                color="primary"
                outlined
                @click="back"
              />

              <BaseButton
                id="btn-save"
                name="btn-save"
                title="Salvar"
                color="primary"
                type="submit"
                :disabled="!valid"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { randomId } from '@/helpers/utils';
import { formatBytes } from '@/helpers/formatting';
import { showMessage } from '@/helpers/messages';
import { SupportService } from '@/services/api/support';

export default {
  mixins: [rulesMixin],

  data: () => ({
    valid: true,
    model: {
      categoryId: null,
      subject: null,
      message: null,
      files: []
    },
    headers: [
      { text: 'Arquivo', value: 'name' },
      { text: 'Tamanho', value: 'size' },
      { text: '', value: 'action' }
    ],
    isFiles: false,
    files: [],
    items: []
  }),

  methods: {
    formatBytes,

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const params = Object.assign({}, this.model);
        params.files = params.files.map(x => x.file);

        const supportService = new SupportService();
        const { status } = await supportService.create(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.back();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$router.push({ name: 'suportes' });
    },

    remove(item) {
      const indexItems = this.items.findIndex(x => x.id === item.id);
      const indexFiles = this.model.files.findIndex(x => x.id === item.id);

      if (indexItems !== -1) {
        this.items.splice(indexItems, 1);
      }

      if (indexFiles !== -1) {
        this.model.files.splice(indexFiles, 1);
      }
    },

    addFile(files) {
      if (files.length > 0) {
        files.forEach(item => {
          let id = randomId();

          this.items.push({ id, name: item.name, size: item.size, file: item });
          this.model.files.push({ id, file: item });
        });

        setTimeout(() => {
          this.files = [];
        }, 200);
      }
    },

    changeIsFiles(value) {
      if (value === false) {
        this.files = [];
        this.items = [];
      }
    }
  }
};
</script>
